import type { useRouteLoaderData } from '@remix-run/react';

import { updateCareerKeywordSchema } from '#app/action/update-career-keyword';
import { Autocomplete } from '#app/components/forms';
import { Button } from '#app/components/ui/button/button';
import { TextButton } from '#app/components/ui/button/text-button';
import { ComboBoxResponsive } from '#app/components/ui/combobox/combobox';
import { Modal, ModalContent, ModalFooter, ModalHeader, ModalMain, ModalTitle, ModalTrigger } from '#app/components/ui/modal/modal';
import { GlobalAction } from '#app/utils/global-action';
import { getInputProps, useForm } from '@conform-to/react';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';
import { useFetcher } from '@remix-run/react';
import React, { useEffect, useRef, useState } from 'react';

import type {action as rootAction, loader as rootLoader} from '../../../root';

interface UpdateCareerKeywordDialogProps {
  readonly rootData: ReturnType<typeof useRouteLoaderData<typeof rootLoader>>
}
export const UpdateCareerKeywordDialog: React.FC<UpdateCareerKeywordDialogProps> = (props) => {
  
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = React.useState<string | undefined>(props.rootData?.user?.careerKeyword?.name);


  return (
    <Modal onOpenChange={(v) => {
      if (v === false) {
        void setSelected(props.rootData?.user?.careerKeyword?.name);
      }
      setOpen(v);
    }} open={open}>
      <ModalTrigger asChild>
        <TextButton size='small' variant={'primary'}>
          전문 분야 수정
        </TextButton>
      </ModalTrigger>
      <ModalContent 
        showClose={true}
        size='medium'
      >
        <UpdateCareerKeyword rootData={props.rootData} selected={selected} setOpen={setOpen} setSelected={setSelected} />
      </ModalContent> 
    </Modal>
  );
};


const UpdateCareerKeyword: React.FC<UpdateCareerKeywordDialogProps & {
  selected: string | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSelected: React.Dispatch<React.SetStateAction<string | undefined>>
}> = ({rootData, selected, setOpen, setSelected}) => {
  const fetcher = useFetcher<typeof rootAction>();

  const [_open, _setOpen] = useState(false);
  const ref = useRef<HTMLFormElement | null>(null);
  const [, transition] = React.useTransition();

  const [form, fields] = useForm({
    constraint: getZodConstraint(updateCareerKeywordSchema),
    id        : GlobalAction.ProfileEditUpdateCareerKeyword,
    onValidate: ({ formData }) => {
      return parseWithZod(formData, {schema: updateCareerKeywordSchema});
    }
  });

    
  useEffect(() => {
    if (fetcher.data?.status === 'success') {
      void setOpen(false);
    }
  }, [fetcher.data, setOpen]);


  return <fetcher.Form action='/' id={form.id} method='post' ref={ref}>
    <ModalHeader className='mx-5 mt-5 mb-[0.875rem]'>
      <ModalTitle className='text-subtitle-1-bold-desktop xs:font-sfProBold sm:font-pretendardBold'>
        전문 분야 수정
      </ModalTitle>
    </ModalHeader>
    <ModalMain className={'px-5 py-2'}>
      <ComboBoxResponsive
        error={false}
        inputProps={{
          autoFocus: false,
          onBlur   : () => {
            setTimeout(() => _setOpen(false), 100);
          },
          onFocus    : () => transition(() => _setOpen(true)),
          placeholder: selected,
          value      : selected,
          ...getInputProps(fields[GlobalAction.ProfileEditUpdateCareerKeyword], { type: 'text'})
        }}
        items={
          rootData!.groupedKeyword['커리어']!.map(
            (keyword) => ({
              label: keyword.name,
              value: keyword.name,
            }))
        }
        setSelected={(item) => {
          setSelected(item.value);
          void _setOpen(false);
        }}
      />
    </ModalMain>
    <ModalFooter className='border-none'>
      <Button disabled={false} rounded={'md'} size={'medium'} variant={'primary'}>저장</Button>
    </ModalFooter>
  </fetcher.Form>;
};