import { prisma } from '#app/utils/db.server';
import { GlobalAction } from '#app/utils/global-action';
import { logger } from '#app/utils/logger';
import { parseWithZod } from '@conform-to/zod';
import { z } from 'zod';

export const updateIntroductionSchema = z.object({
  [GlobalAction.ProfileEditUpdateIntroduction]: z.string().min(1).max(128),
});

/**
 * @description 자기소개 
 * @param userId 가입한 유저 아이디
 * @param formData 폼 데이터
 */
export const updateIntroduction = async (userId: number, formData: FormData) => {
  logger.info('UpdateIntroduction called!');
  const response = await parseWithZod(formData, {
    async : true,
    schema: updateIntroductionSchema.superRefine(async (data) => {
      const user = await prisma.user.findFirst({
        select: { introduction: true },
        where : {
          id: userId,
        }
      });
      await prisma.$transaction([
        prisma.actionHistory.create({
          data: {
            action: 'update-introduction',
            extra : {
              asIs: user?.introduction,
              toBe: data[GlobalAction.ProfileEditUpdateIntroduction],
            },
            userId: userId
          }
        }),
        prisma.user.update({
          data: {
            introduction: data[GlobalAction.ProfileEditUpdateIntroduction],
          },
          where: { id: userId },
        }),
      ]);
    }),
  },);
  logger.info('UpdateIntroduction done!');
  return response.status;
};