import { prisma } from '#app/utils/db.server';
import { GlobalAction } from '#app/utils/global-action';
import { logger } from '#app/utils/logger';
import { parseWithZod } from '@conform-to/zod';
import { z } from 'zod';

export const updateCareerKeywordSchema = z.object({
  [GlobalAction.ProfileEditUpdateCareerKeyword]: z.string(),
});

/**
 * @description 직업 변경
 * @param userId 가입한 유저 아이디
 * @param formData 폼 데이터
 */
export const updateCareerKeyword = async (userId: number, formData: FormData) => {
  logger.info('UpdateCareer called!');
  const response = await parseWithZod(formData, {
    async : true,
    schema: updateCareerKeywordSchema.superRefine(async (data, ctx) => {
      const keyword = await prisma.keyword.findFirst({
        where: {
          name: data[GlobalAction.ProfileEditUpdateCareerKeyword],
        },
      });

      if (keyword === null) {
        ctx.addIssue({
          code   : 'custom',
          message: '존재하지 않는 직업입니다.',
        });
        return ctx;
      }
      
      await prisma.user.update({
        data: {
          careerKeyword: {
            connect: {
              id: keyword.id,
            }
          },
        },
        where: { id: userId },
      });

      await prisma.followKeyword.upsert({
        create: {
          keywordId: keyword.id,
          userId,
        },
        update: {
          keywordId: keyword.id,
        },
        where: {
          userId_keywordId: {
            keywordId: keyword.id,
            userId,
          },
        },
      });

    }),
  },);
  logger.info('UpdateCareer done!');
  return response.status;
};