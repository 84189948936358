import type { useRouteLoaderData} from '@remix-run/react';

import { Button } from '#app/components/ui/button/button';
import { useFetcher } from '@remix-run/react';
import React from 'react';

import type { loader as rootLoader } from '../../../root';

interface ImageProps {
  rootData: ReturnType<typeof useRouteLoaderData<typeof rootLoader>>
  
}
export const Image: React.FC<ImageProps> = ({ rootData }) => {
  const fetcher = useFetcher();
  const formRef = React.useRef<HTMLFormElement>(null);
  return (
    <fetcher.Form ref={formRef}>
      <div className='grid grid-flow-row gap-4 '>
        <img
          alt='userProfileImage'
          className='bg-red-50 w-[5.75rem] h-[5.75rem] object-cover rounded-lg object-center'
          src={rootData?.user?.imageUrl ?? ''}
        />
        <span className='grid mx-2'>
          <input className='w-0 h-0' id="profile-image" name="profile-image"
            onChange={() => {
              fetcher.submit(formRef.current, {
                action : '/resources/upload-image',
                encType: 'multipart/form-data',
                method : 'post',
              });
            }} type="file" />
          <Button
            onClick={() => {
              document.getElementById('profile-image')?.click();
            }}
            rounded={'md'}
            size='small'
            type='button'
            variant='gray'
          >
            사진 수정
          </Button>
        </span>
      </div>
    </fetcher.Form>
  );
};