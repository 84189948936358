import { verifyCompanySchema } from '#app/action/verify-company';
import { GlobalAction } from '#app/utils/global-action';
import { getInputProps, useForm } from '@conform-to/react';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';
import { useFetcher } from '@remix-run/react';
import React, { useRef } from 'react';

import type {action as rootAction } from '../../root';

import { Field } from '../forms';
import { Button } from '../ui/button/button';
import { Modal, ModalContent, ModalHeader, ModalMain, ModalTitle } from '../ui/modal/modal';
import { SendEmail } from './onboarding-dialog';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const VerifyCompanyDialog: React.FC<Props> = ({ open, setOpen}) => {
  const fetcher = useFetcher<typeof rootAction>();
  const ref = useRef<HTMLFormElement | null>(null);
  
  const [complete, setComplete] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [errors, setErrors] = React.useState<null[] | string[]>([]);
  const [form, fields] = useForm({
    constraint: getZodConstraint(verifyCompanySchema),
    id        : GlobalAction.VerifyCompany,
    onValidate: ({ formData }) => {
      return parseWithZod(formData, {schema: verifyCompanySchema});
    },
  });
  
  React.useEffect(() => {
    if (fetcher.data?.status==='success') {
      void setComplete(true);
    }
  }, [fetcher.data]);

  React.useEffect(() => {
    const notAvailableDomains = [
      'naver.com',
      'gmail.com',
      'kakao.com',
      'nate.com',
      'google.com',
      'hanmail.net',
      'hotmail.com',
      'nate.com',
    ];
    if (email.length > 64) {
      void setErrors(['64자 이내로 입력해주세요']);
      return;
    }

    if (!new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i).test(email) && email.includes('@')) {
      void setErrors(['회사/소속 이메일을 입력해 주세요.']);
      return;
    }

    // 앗.. 내 실수.. 합쳐야하는데 onboarding-dialog.tsx에 있는 코드랑..
    if(email.includes('@')) {
      const domain = email.split('@')[1];
      if(notAvailableDomains.includes(domain)) {
        void setErrors(['회사/소속 이메일을 입력해 주세요.']);
        return;
      }
    }    
    void setErrors([]);
  }, [email]);

  
  const disabled = errors.length > 0 || email.length === 0 || !email.includes('@');
  return (
    <Modal 
      onOpenChange={setOpen}
      open={open}
    >
      <ModalContent className='w-[50rem] h-[37.5rem] grid grid-rows-[auto_1fr]' showClose={true} size='large'>
        {complete && <SendEmail email={email} onNext={() => {
          void setComplete(false);
          void setOpen(false);
        }} />}
        <fetcher.Form id={form.id} method='POST' ref={ref}>
          <ModalHeader className='mt-6'>
            <ModalTitle className='text-title-2-bold-desktop xs:font-sfProBold sm:font-pretendardBold'>
            회사 정보 인증
            </ModalTitle>
          </ModalHeader>    
          <ModalMain className='px-10 py-5'>
            <section className='grid grid-flow-row gap-5'>
              <Field
                errors={errors}
                inputProps={{
                  onChange: (e) => {
                    const value = e.currentTarget.value;
                    void setEmail(value);
                  },
                  placeholder: '회사명 혹은 소속 이름 (예: 북커버리)',
                  ...getInputProps(fields.email, { type: 'text' }),
                }}
                labelProps={{
                  children: '회사 혹은 소속',
                }}
              />
              <div className='bg-gray-5 p-4 rounded-lg text-caption-1-regular xs:font-sfProLight sm:font-pretendardLight text-gray-70'>
                <p>
                도메인 인증을 하지 않는 경우, 회사 식별이 되지 않아 로고 등 회사 정보 반영이 어려울 수 있습니다.
                </p>
                <p>
                인증한 회사정보는 정보공유 및 네트워크를 위해 선택적으로 사용할 수 있으며, 설정에 따라 비공개할 수 있습니다. 
                </p>
              </div>
              <div className='grid justify-start'>
                <Button
                  children='회사 이메일 인증하기'
                  disabled={disabled}
                  rounded='md'
                  size='medium'
                  variant='primary'
                />
              </div>
            </section>
          </ModalMain>
        </fetcher.Form>
      </ModalContent>
    </Modal>
  );
};