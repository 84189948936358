import { prisma } from '#app/utils/db.server';
import { GlobalAction } from '#app/utils/global-action';
import { logger } from '#app/utils/logger';
import { parseWithZod } from '@conform-to/zod';
import { z } from 'zod';

export const updateDisplayCareerSchema = z.object({
  [GlobalAction.ProfileEditUpdateDisplayCareer]: z.string().min(2).max(64),
});

/**
 * @description 직업 타이틀 
 * @param userId 가입한 유저 아이디
 * @param formData 폼 데이터
 */
export const updateDisplayCareer = async (userId: number, formData: FormData) => {
  logger.info('UpdateDisplayCareer called!');
  const response = await parseWithZod(formData, {
    async : true,
    schema: updateDisplayCareerSchema.superRefine(async (data) => {
      await prisma.user.update({
        data: {
          displayCareer: data[GlobalAction.ProfileEditUpdateDisplayCareer],
        },
        where: { id: userId },
      });
    }),
  },);
  logger.info('UpdateDisplayCareer done!');
  return response.status;
};