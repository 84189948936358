import { useRouteLoaderData } from '@remix-run/react';
import React, { useState } from 'react';

import type { loader as rootLoader } from '../../root';

import { Modal, ModalContent,  ModalHeader, ModalMain, ModalTitle } from '../ui/modal/modal';
import { Company } from './profile-edit-dialog/company';
import { Image } from './profile-edit-dialog/image';
import { UpdateCareerKeywordDialog } from './profile-edit-dialog/update-career-keyword';
import { UpdateDisplayCareer } from './profile-edit-dialog/update-display-career';
import { UpdateIntroductionDialog } from './profile-edit-dialog/update-introduction';
import { UpdateNameDialog } from './profile-edit-dialog/update-name';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}
export const ProfileEditDialog: React.FC<Props> = ({ open, setOpen }) => {
  const rootData = useRouteLoaderData<typeof rootLoader>('root');

  return (
    <Modal 
      onOpenChange={setOpen}
      open={open}
    >
      <ModalContent className='w-[50rem] h-[37.5rem] grid grid-rows-[auto_1fr]' showClose={true} size='large'>
        <ModalHeader className='mt-6'>
          <ModalTitle className='text-title-2-bold-desktop xs:font-sfProBold sm:font-pretendardBold'>
              프로필 수정
          </ModalTitle>
        </ModalHeader>    
        <ModalMain className='px-10 py-5 overflow-auto '>
          <section className='grid grid-flow-row gap-5'>
            <h2 className='text-subtitle-1-bold-desktop xs:font-sfProBold sm:font-pretendardBold text-gray-90'>기본 정보</h2>
            <section className='grid grid-flow-col gap-[3.75rem] grid-cols-[auto_1fr] border-b-[1px] border-b-gray-20 '>
              <Image rootData={rootData} />
              <Articles rootData={rootData} />
            </section>
            <Company rootData={rootData} />
            <div />
            <div />
          </section>
        </ModalMain>
      </ModalContent>
    </Modal>
  );
};



interface ArticleProps {
  rootData: ReturnType<typeof useRouteLoaderData<typeof rootLoader>>
}
const Articles: React.FC<ArticleProps> = ({ rootData }) => {
  const [, setModal] = useState({
    careerKeyword: false,
    displayCareer: false,
    introduction : false,
    name         : false,
  });
  
  type ArticleType = {
    readonly label: string;
    readonly modal?: React.ReactNode;
    readonly onClick: () => void;
    readonly placeholder: string;
    readonly value?: string;
  };
  const articles: ArticleType[] = [
    {
      label      : '이름',
      modal      : <UpdateNameDialog rootData={rootData}  />,
      onClick    : () => setModal(state => ({...state, name: !state.name})),
      placeholder: '성이름',
      value      : rootData?.user?.name || rootData?.user?.email,
    },
    {
      label      : '전문 분야',
      modal      : <UpdateCareerKeywordDialog rootData={rootData} />,
      onClick    : () => setModal(state => ({...state, careerKeyword: !state.careerKeyword})),
      placeholder: '전문 분야',
      value      : rootData?.user?.careerKeyword?.name
    },
    {
      label      : '직업 타이틀',
      modal      : <UpdateDisplayCareer rootData={rootData} />,
      onClick    : () => setModal(state => ({...state, displayCareer: !state.displayCareer})),
      placeholder: '다른 사람들에게 보여지는 이름으로, 직접 변경하지 않으면 전문 분야로 보여집니다.',
      value      : rootData?.user?.displayCareer || undefined,
    },
    {
      label      : '자기소개',
      modal      : <UpdateIntroductionDialog rootData={rootData} />,
      onClick    : () => setModal(state => ({...state, introduction: !state.introduction})),
      placeholder: '다른 사람들에게 나를 소개할 수 있는 한줄 소개를 작성해주세요.',
      value      : rootData?.user?.introduction || undefined,
    },
  ];

  
  return (
    <div className='grid grid-flow-row gap-[1.875rem] mb-5'>
      {articles.map((article) => (
        <article className='grid grid-flow-row gap-2.5' key={article.label}>
          <h2 className='text-label-2-bold xs:font-sfProBold sm:font-pretendardBold'>
            {article.label}
          </h2>
          <div className='grid grid-flow-col items-center justify-between gap-2'>
            <p className='text-label-1-regular xs:font-sfProLight sm:font-pretendardLight text-gray-90 break-words text-ellipsis overflow-hidden'>
              {article.value
                ? <span>{article.value}</span>
                : <span className='text-gray-50 text-label-2-regular xs:font-sfProLight sm:font-pretendardLight'>{article.placeholder}</span>}
            </p>
            {article.modal}
          </div>
        </article>
      ))}
    </div>
  );
};
