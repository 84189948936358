import { prisma } from '#app/utils/db.server';
import { GlobalAction } from '#app/utils/global-action';
import { logger } from '#app/utils/logger';
import { parseWithZod } from '@conform-to/zod';
import { z } from 'zod';

export const visibilityCompanySchema = z.object({
  [GlobalAction.ProfileEditVisibilityCompany]: z.string(),
});

/**
 * @description 인증한 회사를 보일지 말지
 * @param userId 가입한 유저 아이디
 * @param formData 폼 데이터
 */
export const visibilityCompany = async (userId: number, formData: FormData) => {
  logger.info('visibilityCompany called!');
  await parseWithZod(formData, {
    async : true,
    schema: visibilityCompanySchema.superRefine(async (data) => {
      await prisma.$transaction([
        prisma.user.update({
          data: {
            showCompany: data[GlobalAction.ProfileEditVisibilityCompany] === 'true' ? true : false,
          },
          where: { id: userId },
        }),
        prisma.actionHistory.create({
          data: {
            action: 'visibility-company',
            extra : {
              asIs: data[GlobalAction.ProfileEditVisibilityCompany] === 'true' ? false : true,
              toBe: data[GlobalAction.ProfileEditVisibilityCompany] === 'true' ? true : false,
            },
            userId: userId
          }
        })
      ]);
    }),
  },);
  logger.info('visibilityCompany done!');
};