import type { useRouteLoaderData} from '@remix-run/react';

import { visibilityCompanySchema } from '#app/action/visibility-company';
import { Field } from '#app/components/forms';
import { Icon } from '#app/components/ui/icon';
import { Switch } from '#app/components/ui/switch/switch';
import { formatDate } from '#app/utils/date';
import { GlobalAction } from '#app/utils/global-action';
import { getInputProps, useForm } from '@conform-to/react';
import { getZodConstraint } from '@conform-to/zod';
import { useFetcher } from '@remix-run/react';
import React, { useRef } from 'react';

import type {action as rootAction, loader as rootLoader} from '../../../root';

import { VerifyCompanyDialog } from '../verify-company-dialog';

interface CompanyProps {
  rootData: ReturnType<typeof useRouteLoaderData<typeof rootLoader>>
}
export const Company: React.FC<CompanyProps> = ({ rootData }) => {
  const ref = useRef<HTMLFormElement | null>(null);
  const [form, fields] = useForm({
    constraint: getZodConstraint(visibilityCompanySchema),
  });
  const fetcher = useFetcher<typeof rootAction>();
  const [checked, setChecked] = React.useState<boolean>(!!rootData?.user?.showCompany);

  const [open, setOpen] = React.useState(false);
  
  
  if (!rootData?.user) {
    throw new Error('User not found');
  }


  return (
    <fetcher.Form action='/' id={form.id} method='post' ref={ref}>
      <section className='grid grid-flow-row gap-5 mt-5'>
        <h1 className='text-subtitle-1-bold-desktop xs:font-sfProBold sm:font-pretendardBold text-gray-90'>회사 정보</h1>
        <article className='grid grid-flow-col justify-between'>
          <div className='grid grid-flow-row gap-1'>
            <h2 className='text-label-2-bold xs:font-sfProBold sm:font-pretendardBold'>
              내 회사
            </h2>
            <span className='text-label-2-regular xs:font-sfProLight sm:font-pretendardLight text-gray-50'>
              {rootData.user.companyHistory!.length > 0
                ? (
                  <div className='grid grid-flow-col gap-2.5 mt-4'>
                    <div>
                      <img
                        alt='userProfileImage'
                        className='bg-red-50 w-[2.5rem] h-[2.5rem] object-cover rounded-md object-center'
                        src={rootData.user.companyHistory![0].company.logo ?? 'https://bookcovery-web-images.s3.ap-northeast-2.amazonaws.com/company/logo/bookcovery.png'} />
                    </div>
                    <div className='grid grid-flow-row gap-[0.03125rem]'>
                      <span className='grid grid-flow-col gap-1 justify-start text-gray-90 text-label-1-regular xs:font-sfProLight sm:font-pretendardLight'>{rootData.user.companyHistory![0].company.name}
                        {rootData.user.verifiedCompany && <Icon className='text-verified' name='fill-confirmed' size='medium' />}
                      </span>
                      {
                        rootData.user.verifiedCompany 
                          ? (
                            <span className='text-gray-50 text-caption-2-regular xs:font-sfProLight sm:font-pretendardLight'>{formatDate(new Date(rootData.user.verifiedCompany.verifiedAt!))}</span>
                          )
                          : (
                            <span className='text-gray-50 text-caption-2-regular xs:font-sfProLight sm:font-pretendardLight'>{formatDate(new Date(rootData.user.companyHistory![0].createdAt))}</span>
                          )
                      }
                    </div>
                  </div>
                )
                : '회사 정보 등록하고 인증하기'}
            </span>
          </div>
          <Icon className='hover:cursor-pointer' name='fill-chevron-right' onClick={() => {
            setOpen(!open);
          }} size='large' />
        </article>
        {rootData.user.companyHistory!.length > 0 && (
          <article className='mt-2.5 grid grid-flow-col justify-between'>
            <div className='grid grid-flow-row gap-1'>
              <h2 className='text-label-2-bold xs:font-sfProBold sm:font-pretendardBold'>
                회사 정보 표시
              </h2>
              <p className='text-gray-50 text-label-2-regular xs:font-sfProLight sm:font-pretendardLight'>
                회사정보를 비공개할 수 있습니다.
              </p>
            </div>
            <div className='grid grid-flow-col gap-2 items-center'>
              {checked && <span className='text-label-2-regular xs:font-sfProLight sm:font-pretendardLight text-point-blue-60'>공개</span>}
              {!checked && <span className='text-label-2-regular xs:font-sfProLight sm:font-pretendardLight text-gray-60'>비공개</span>}
              <Switch
                checked={checked}
                onCheckedChange={(v) => {
                  void setChecked(v);
                  void fetcher.submit(ref.current, { method: 'POST' });
                } }/>
            </div>
          </article>
        )}
        <div className='h-0 hidden'>
          <Field
            inputProps={{
              value: (!checked).toString(),
              ...getInputProps(fields[GlobalAction.ProfileEditVisibilityCompany], {type: 'hidden'}),
            }}
          />
        </div>
      </section>
      {
        open && <VerifyCompanyDialog open={open} setOpen={setOpen} />
      }
    </fetcher.Form>
  );
};