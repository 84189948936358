import type { useRouteLoaderData} from '@remix-run/react';

import { updateNameSchema } from '#app/action/update-name';
import { Field } from '#app/components/forms';
import { Button } from '#app/components/ui/button/button';
import { TextButton } from '#app/components/ui/button/text-button';
import { Modal, ModalContent, ModalFooter, ModalHeader, ModalMain, ModalTitle, ModalTrigger } from '#app/components/ui/modal/modal';
import { GlobalAction } from '#app/utils/global-action';
import { getInputProps, useForm } from '@conform-to/react';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';
import { useFetcher } from '@remix-run/react';
import React, { useEffect, useRef, useState } from 'react';

import type {action as rootAction, loader as rootLoader} from '../../../root';

interface UpdateNameDialogProps {
  readonly rootData: ReturnType<typeof useRouteLoaderData<typeof rootLoader>>
}
export const UpdateNameDialog: React.FC<UpdateNameDialogProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(props.rootData?.user?.name || props.rootData?.user?.email || '');

  return (
    <Modal onOpenChange={(v) => {
      if (v === false) {
        void setName(props.rootData?.user?.name || props.rootData?.user?.email || '');
      }
      void setOpen(v);
    }} open={open}>
      <ModalTrigger asChild>
        <TextButton size='small' variant={'primary'}>
          이름 수정
        </TextButton>
      </ModalTrigger>
      <ModalContent 
        showClose={true}
        size='medium'
      >
        <UpdateForm name={name} rootData={props.rootData} setName={setName} setOpen={setOpen} />
      </ModalContent> 
    </Modal>
  );
};


export const UpdateForm: React.FC<UpdateNameDialogProps & {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = (props) =>  {
  const ref = useRef<HTMLFormElement | null>(null);

  const fetcher = useFetcher<typeof rootAction>();
  const [form, fields] = useForm({
    constraint: getZodConstraint(updateNameSchema),
    id        : GlobalAction.ProfileEditUpdateName,
    onValidate: ({ formData }) => {
      return parseWithZod(formData, {schema: updateNameSchema});
    }
  });

  

  useEffect(() => {
    if (fetcher.data?.status==='success') {
      props.setOpen(false);
    }

  }, [fetcher.data, props]);

  return <fetcher.Form action='/' id={form.id} method='post' ref={ref}>
    <ModalHeader className='mx-5 mt-5 mb-[0.875rem]'>
      <ModalTitle className='text-subtitle-1-bold-desktop xs:font-sfProBold sm:font-pretendardBold'>
        이름 수정
      </ModalTitle>
    </ModalHeader>
    <ModalMain className={'px-5 py-2'}>
      <Field
        inputProps={{
          maxLength: 64,
          minLength: 2,
          onChange : (e) => {
            props.setName(e.currentTarget.value);
          },
          value: props.name,
          ...getInputProps(fields[GlobalAction.ProfileEditUpdateName], { type: 'text' }),
        }}
      />
    </ModalMain>
    <ModalFooter className='border-none'>
      <Button disabled={props.name.length < 2 || props.name.length > 64} rounded={'md'} size={'medium'} variant={'primary'}>저장</Button>
    </ModalFooter>
  </fetcher.Form>;
};