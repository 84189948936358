import type { useRouteLoaderData} from '@remix-run/react';

import { updateIntroductionSchema } from '#app/action/update-introduction';
import { Textarea } from '#app/components/forms';
import { Button } from '#app/components/ui/button/button';
import { TextButton } from '#app/components/ui/button/text-button';
import { Modal, ModalContent, ModalFooter, ModalHeader, ModalMain, ModalTitle, ModalTrigger } from '#app/components/ui/modal/modal';
import { GlobalAction } from '#app/utils/global-action';
import { getInputProps, useForm } from '@conform-to/react';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';
import { useFetcher } from '@remix-run/react';
import React, { useEffect, useRef, useState } from 'react';

import type {action as rootAction, loader as rootLoader} from '../../../root';

interface UpdateIntroductionProps {
  readonly rootData: ReturnType<typeof useRouteLoaderData<typeof rootLoader>>
}
export const UpdateIntroductionDialog: React.FC<UpdateIntroductionProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [introduction, setIntroduction] = useState(props.rootData?.user?.introduction || '');
  
  return (
    <Modal onOpenChange={(v) => {
      if(v === false) {
        void setIntroduction(props.rootData?.user?.introduction || '');
      }
      setOpen(v);
    }} open={open}>
      <ModalTrigger asChild>
        <TextButton size='small' variant={'primary'}>
          자기소개 수정
        </TextButton>
      </ModalTrigger>
      <ModalContent 
        showClose={true}
        size='medium'
      >
        <UpdateIntroduction introduction={introduction} rootData={props.rootData} setIntroduction={setIntroduction} setOpen={setOpen} />
      </ModalContent> 
    </Modal>
  );
};

const UpdateIntroduction: React.FC<UpdateIntroductionProps & {
  introduction: string;
  setIntroduction: React.Dispatch<React.SetStateAction<string>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = ({introduction, rootData, setIntroduction, setOpen}) => {
  const fetcher = useFetcher<typeof rootAction>();
  const ref = useRef<HTMLFormElement | null>(null);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const [form, fields] = useForm({
    constraint: getZodConstraint(updateIntroductionSchema),
    id        : GlobalAction.ProfileEditUpdateIntroduction,
    onValidate: ({ formData }) => {
      return parseWithZod(formData, {schema: updateIntroductionSchema});
    }
  });


  useEffect(() => {
    if (fetcher.data?.status==='success') {
      void setOpen(false);
    }
  }, [fetcher.data, setOpen]);

  return  <fetcher.Form action='/' id={form.id} method='post' ref={ref}>
    <ModalHeader className='mx-5 mt-5 mb-[0.875rem]'>
      <ModalTitle className='text-subtitle-1-bold-desktop xs:font-sfProBold sm:font-pretendardBold'>
    자기소개 수정
      </ModalTitle>
    </ModalHeader>
    <ModalMain className={'px-5 py-2'}>
      <Textarea
        className='h-24'
        onChange={(e) => {
          setIntroduction(e.currentTarget.value);
        }}
        onFocus={() => textAreaRef.current?.setSelectionRange(introduction.length, introduction.length)}
        placeholder='다른 사람들에게 나를 소개할 수 있는 한줄 소개를 작성해주세요. (최대 100자)'
        ref={textAreaRef}
        value={introduction}
        {...getInputProps(fields[GlobalAction.ProfileEditUpdateIntroduction], { type: 'text' })}
      />
    </ModalMain>
    <ModalFooter className='border-none'>
      <Button disabled={introduction.length < 1 || introduction.length > 128} rounded={'md'} size={'medium'} variant={'primary'}>저장</Button>
    </ModalFooter>
  </fetcher.Form>;
};