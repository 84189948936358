import type { useRouteLoaderData} from '@remix-run/react';

import { updateDisplayCareerSchema } from '#app/action/update-display-career';
import { Field } from '#app/components/forms';
import { Button } from '#app/components/ui/button/button';
import { TextButton } from '#app/components/ui/button/text-button';
import { Modal, ModalContent, ModalFooter, ModalHeader, ModalMain, ModalTitle, ModalTrigger } from '#app/components/ui/modal/modal';
import { GlobalAction } from '#app/utils/global-action';
import { getInputProps, useForm } from '@conform-to/react';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';
import { useFetcher } from '@remix-run/react';
import React, { useEffect, useRef, useState } from 'react';

import type {action as rootAction, loader as rootLoader} from '../../../root';

interface UpdateDisplayCareerProps {
  readonly rootData: ReturnType<typeof useRouteLoaderData<typeof rootLoader>>
}
export const UpdateDisplayCareer: React.FC<UpdateDisplayCareerProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [displayCareer, setDisplayCareer] = useState(props.rootData?.user?.displayCareer || props.rootData?.user?.displayCareer || props.rootData?.user?.careerKeyword?.name || '');
  

  return (
    <Modal onOpenChange={(v) => {
      if(v === false) {
        void setDisplayCareer(props.rootData?.user?.displayCareer || props.rootData?.user?.displayCareer || props.rootData?.user?.careerKeyword?.name || '');
      }
      setOpen(v);
    }} open={open}>
      <ModalTrigger asChild>
        <TextButton size='small' variant={'primary'}>
          직업 타이틀 수정
        </TextButton>
      </ModalTrigger>
      <ModalContent 
        showClose={true}
        size='medium'
      >
        <UpdateDisplayCareerDialog displayCareer={displayCareer} rootData={props.rootData} setDisplayCareer={setDisplayCareer} setOpen={setOpen} />
      </ModalContent> 
    </Modal>
  );
};

const UpdateDisplayCareerDialog: React.FC<UpdateDisplayCareerProps & {
  displayCareer: string;
  setDisplayCareer: React.Dispatch<React.SetStateAction<string>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = ({displayCareer, setDisplayCareer, setOpen}) => {
  const fetcher = useFetcher<typeof rootAction>();
  const ref = useRef<HTMLFormElement | null>(null);

  const [form, fields] = useForm({
    constraint: getZodConstraint(updateDisplayCareerSchema),
    id        : GlobalAction.ProfileEditUpdateDisplayCareer,
    onValidate: ({ formData }) => {
      return parseWithZod(formData, {schema: updateDisplayCareerSchema});
    }
  });


  useEffect(() => {
    if (fetcher.data?.status==='success') {
      void setOpen(false);
    }

  }, [fetcher.data, setOpen]);


  return <fetcher.Form action='/' id={form.id} method='post' ref={ref}>
    <ModalHeader className='mx-5 mt-5 mb-[0.875rem]'>
      <ModalTitle className='text-subtitle-1-bold-desktop xs:font-sfProBold sm:font-pretendardBold'>
    직업 타이틀 수정
      </ModalTitle>
    </ModalHeader>
    <ModalMain className={'px-5 py-2'}>
      <Field
        inputProps={{
          maxLength: 64,
          minLength: 2,
          onChange : (e) => {
            setDisplayCareer(e.currentTarget.value);
          },
          placeholder: '다른 사람들에게 보여지는 이름으로, 직접 변경하지 않으면 전문 분야로 보여집니다.',
          value      : displayCareer,
          ...getInputProps(fields[GlobalAction.ProfileEditUpdateDisplayCareer], { type: 'text' }),
        }}
      />
    </ModalMain>
    <ModalFooter className='border-none'>
      <Button disabled={displayCareer.length < 2 || displayCareer.length > 64} rounded={'md'} size={'medium'} variant={'primary'}>저장</Button>
    </ModalFooter>
  </fetcher.Form>;
};