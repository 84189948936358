import { prisma } from '#app/utils/db.server';
import { GlobalAction } from '#app/utils/global-action';
import { logger } from '#app/utils/logger';
import { parseWithZod } from '@conform-to/zod';
import { z } from 'zod';

export const updateNameSchema = z.object({
  [GlobalAction.ProfileEditUpdateName]: z.string().min(2).max(64),
});

/**
 * @description 이름 변경
 * @param userId 가입한 유저 아이디
 * @param formData 폼 데이터
 */
export const updateName = async (userId: number, formData: FormData) => {
  logger.info('UpdateName called!');
  const response = await parseWithZod(formData, {
    async : true,
    schema: updateNameSchema.superRefine(async (data) => {
      const user = await prisma.user.findFirst({
        select: { email: true, name: true, },
        where : {
          id: userId,
        }
      });
      await prisma.$transaction([
        prisma.user.update({
          data: {
            name: data[GlobalAction.ProfileEditUpdateName],
          },
          where: { id: userId },
        }),
        prisma.actionHistory.create({
          data: {
            action: 'update-name',
            extra : {
              asIs: user?.name || user?.email,
              toBe: data[GlobalAction.ProfileEditUpdateName],
            },
            userId: userId
          }
        })
      ]);
    }),
  },);
  logger.info('UpdateName done!');
  return response.status;
};